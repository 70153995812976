<template>
  <div class="about">
    <div class="about-conent">About</div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.about {
  padding-top: 60px;
  background: #123456;
  .about-conent {
    width: 1080px;
    margin: 0 auto;
  }
}
</style>