<template>
  <div class="PrivacyPolicy">
    <div class="center">
      <h3>Introduction</h3>
      <h6>Project Background</h6>
      <p>
        With the rapid development of blockchain technology, the gaming industry is undergoing an unprecedented transformation. 
        Blockchain games have attracted a lot of attention from players due to their transparency, fairness, and asset ownership. 
        Battle Royale games, known for their intense competition, fast-paced decision-making, and strategic planning, have captivated players. 
        Our project aims to combine the best of both worlds by creating an innovative gaming experience where players can not only enjoy the game but also gain actual economic rewards.
      </p>
      <h6>IVision and Mission</h6>
      <p>
        Our vision is to establish an exciting and fair competitive platform that allows players to achieve victory through strategy and skill, leveraging blockchain technology. 
        Our mission is to drive the application of blockchain technology in casual gaming, create a win-win situation for both players and developers, and encourage community interaction and engagement.
      </p>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>
      <h3>Game Overview</h3>
      <h6>Gameplay</h6>
      <li>
        <b>● Battle Royale Core Mechanics:</b>
        At the start of the game, players are randomly assigned to different rooms. Each room has a hidden killer. In each round, players have 60 seconds to choose a room to evade the killer's attack. The killer's goal is to capture as many players as possible, while the survivors must make optimal choices within a limited time.
      </li>
      <li>
        <b>● Round-Based Concept:</b>
        Every round has a time limit, adding to the tension. Players must make decisions within 35 seconds, using their intuition, strategy, and understanding of market dynamics to challenge survival.
      </li>
      <li>
        <b>● Bitcoin Price Impact:</b>
        In the game, the killer decides which room's players will be captured based on the sum of each digit of the real-time Bitcoin price. This dynamic mechanism increases the unpredictability of the game. Players must monitor both in-game dynamics and external market fluctuations, enhancing the strategic depth of the game.
      </li>
      <h6>Player Roles</h6>
      <li>
        <b>● Killer:</b>
        The killer possesses special skills, using market information to predict player behavior and devise strategies. They must make decisions swiftly in a fast-paced environment.
      </li>
      <li>
        <b>● Survivor:</b>
        Each survivor must make the best possible choice within a limited time, using available information and understanding market changes to avoid risk. This increases the game's strategic depth and player engagement.
      </li>
      <h6>Price Prediction</h6>
      <li>
        Players predict Bitcoin’s price movement (up or down) in real-time. Correct guesses reward the player with gems.
      </li>
      <li>
        Players use BTC price movements to guess the price fluctuations. Each prediction requires "fuel," which naturally replenishes over time, rather than consuming in-game tokens.
      </li>
      <h6>Guess the Riddle</h6>
      <li>
        In this game mode, players use hash values to predict and select the correct winning number to receive token rewards. Players invest tokens in the form of Gemora.
      </li>
      <li>
        <b>● Guess Number: </b>
        Predict the winning number based on the game’s hash value.
      </li>
      <li>
        <b>● Guess Odd or Even:</b>
        Predict whether the winning number is odd or even.
      </li>
      <li>
        <b>● Guess Big or Small:</b>
        Predict whether the winning number is large or small.
        <p></p>
      </li>
      

      <h3>Tokenomics</h3>
      <h6>AVC Token Value</h6>
      <li>
        <b>● What is the AVC Token?</b>
        AVC is the native on-chain token issued within our blockchain game ecosystem, designed to provide a unique reward mechanism and in-game economic model. AVC is not only a key in-game currency but will also function as an asset across games and markets, fostering the healthy growth of the entire ecosystem.
      </li>
      <li>
        <b>● ⅠNova:</b>
        The game’s base currency
      </li>
      <li>
        <b>● ⅡAVC:</b>
        The game’s base currency
      </li>
      <li>
        <b>● ⅢUSDT:</b>
        The game’s base currency
      </li>
      
      <h6>Token Distribution</h6>
      <p>The total supply of AVC tokens for the Battle Royale game is 100 million tokens. The distribution is as follows:</p>
      <li>
        <b>● Development Team and Advisors: </b>
          15%
      </li>
      <li>
        <b>● Game Reward Pool: </b>
          40%
      </li>
      <li>
        <b>● Community & Marketing: </b>
          25%
      </li>
      <li>
        <b>● Partners & Ecosystem Development:</b>
          10%
      </li>
      <li>
        <b>● Reserves & Future Development: </b>
          10%
      </li>
      <p></p>


      <h6>Earning Methods</h6>
      <li>
        <b>● Daily Tasks and Challenges: </b>
        Players can earn the base in-game token "Nova" by completing daily, weekly, and monthly tasks, including participating in games, achieving specific milestones, or engaging within the game. This system encourages player activity and helps new players integrate into the game more quickly.
      </li>
      <li>
        <b>● Competitions and Events:</b>
        Regular competitions and events will offer additional Nova token rewards to players. Outstanding performance or completion of specific tasks will yield token rewards, fostering competition and attracting more players to participate.
      </li>
      <li>
        <b>● Betting to Earn: </b>
        Players can place bets using Nova tokens, and successful bets will yield significant rewards. This mechanism adds urgency and offers a way for players to earn tokens via strategy and luck.
      </li>
      <p></p>

      <h6>Token Use</h6>
      <li>
        <b>● Betting:</b>
        Players can use Nova tokens to place bets in-game, increasing competition and excitement. Each bet provides additional rewards or penalties, enriching the game experience.
      </li>
      <li>
        <b>● BItem Purchase:</b>
        Tokens can be used to purchase in-game items like special abilities, boosters, and shields to gain an advantage.
      </li>
      <li>
        <b>● Event Participation:</b>
        Nova tokens can also be used to participate in various limited-time events and competitions, where players can earn additional rewards.
      </li>
      <p>In the Battle Royale and Guess the Riddle games, players can exchange accumulated Nova tokens for AVC tokens after reaching a certain threshold.</p>


      <h6>Economic Model</h6>
      <li>
        <b>● Supply and Demand:</b>
        The total supply of AVC tokens is finite, ensuring scarcity. As the player base grows, demand for the token will increase, helping to maintain its value.
      </li>
      <li>
        <b>● Incentive Mechanism:</b>
        We will implement multi-level incentive mechanisms, such as daily login rewards, additional tokens for completing specific tasks, and rewards for participating in community activities, to encourage long-term player participation.
      </li>
      <p></p>
      
    
   
      <h3>Technical Architecture</h3>
      <h6>Blockchain Integration</h6>
      <li>
        <b>● Smart Contracts:</b>
        Smart contracts will automate game rules and transaction processes, ensuring transparency and fairness.
      </li>
      <li>
        <b>● Security:</b>
        We will use multi-signature and encryption technologies to protect player data and assets, ensuring a secure gaming experience. Player account information and transaction records will be strictly protected to prevent attacks.
      </li>
      <h6>Real-Time Data Processing</h6>
      <li>
        <b>● Data Source:</b>
        We will rely on reliable APIs to fetch real-time Bitcoin prices and hash values, ensuring that each game round is based on the most current market data. This mechanism not only ensures fairness but also increases player engagement and trust, as they are directly impacted by market fluctuations.
      </li>
      <p></p>
      
      <h3>Community & Ecosystem</h3>
      <h6>Player Community</h6>
      <li>
        <b>● Interactive Platform: </b>
        We will build an interactive platform to encourage players to share their strategies, experiences, and game insights. Discussion forums and social media channels will enhance player engagement and retention.
      </li>
      <li>
        <b>● Forums & Events: </b>
        Regular online events, such as strategy sessions and game livestreams, will strengthen player connections and create a vibrant community culture.
      </li>
      <h6>Events & Tournaments</h6>
      <li>
        <b>● Game Tasks:</b>
        We will periodically release new in-game tasks to attract more players and provide rewards within the game to engage newcomers. These tasks will also increase player participation and enhance their game experience.
      </li>
      <li>
        <b>● Social Activities:</b>
        We will introduce social activities and formats in the player community to increase reward acquisition paths and enhance social interactions among players, improving the overall game atmosphere.
      </li>

      <h6>Incentive Mechanism</h6>
      <li>
        <b>● Achievement System:</b>
        We will establish an achievement system where players earn AVC token rewards for completing specific tasks and goals, adding diversity and challenge to the game.
      </li>


      <p></p>
      <h3>Market Analysis</h3>
      <h6>Target Market</h6>
      <li>
        <b>● Player Demographics: </b>
        Our target players include blockchain gaming enthusiasts, players who enjoy fast-paced games, and individuals interested in cryptocurrency. Through market research, we will precisely identify our target audience and tailor our marketing strategies.
      </li>
      <h6>Competitive Analysis</h6>
      <li>
        <b>● Competitors: </b>
        We will analyze existing blockchain casual games, evaluating their strengths and weaknesses. By examining their game mechanics, user experience, and community activities, we will learn from their successes and create a more competitive strategy.     
      </li>
      <li>
        <b>● Differentiation:</b>
        We will ensure a unique market position through distinctive game mechanics, a rich token economy, and active community engagement, attracting more players to our platform.
      </li>
      <p></p>


      <h3>Team Introduction</h3>
      <h6>Core Team</h6>
      <li>
        <b>● Game Developers:</b>
        Our team members come from renowned game companies with rich experience in game development and design. They have worked on several successful blockchain game projects and have a deep technical background.
      </li>
      <li>
        <b>● Blockchain Experts:</b>
        Our technical team has years of blockchain development experience, ensuring the project's security and scalability. We will continually optimize smart contracts to improve system stability and efficiency.
      </li>
      <h6>Advisory Team</h6>
      <li>
        <b>● Industry Advisors:</b>
        We have hired several well-known blockchain and gaming industry advisors to provide strategic guidance and market advice, helping us maintain competitiveness in a constantly evolving market.
      </li>
      <p></p>

      <h3>Roadmap</h3>
      <h6>Development Phases</h6>
      <li>
        <b>● Phase 1:</b>
        Complete game concept design and prototype development, conduct market research, and define the target player base.
      </li>
      <li>
        <b>● Phase 2:</b>
        Internal testing to optimize game mechanics and user experience, ensuring stability and playability.
      </li>
      <li>
        <b>● Phase 3:</b>
        Launch public beta, collect player feedback, and make adjustments based on the feedback to ensure the game meets market needs.
      </li>

      <h6>Future Outlook</h6>
      <li>
        <b>●New Features:</b>
        In the future, we plan to introduce additional characters, items, special events, and game modes to enhance the player experience. We will explore further opportunities to meet the diverse needs of different types of players and enrich the game community and content.
      </li>
      <p></p>



      <h3>Risks & Challenges</h3>
      <h6>Identifying Potential Risks</h6>
      <li>
        <b>● Market Volatility:</b>
        The volatility of the cryptocurrency market could affect the in-game economy, potentially leading to player loss.
      </li>  
      <li>
        <b>● Increased Competition:</b>
        By regularly updating the game, introducing new content, and optimizing the user experience, we will ensure that our players remain engaged.
      </li>
      <p></p>


      <h3>Token Utility</h3>
      <li>
        <b>● Betting Function:</b>
        Players can use AVC tokens to place bets within the game, adding more competitiveness and excitement. Each bet carries different risks and rewards, and players must strategize to maximize their returns. This mechanism enhances player involvement and increases the tension in the game.
      </li>
      <li>
        <b>● Participating in Special Events:</b>
        AVC tokens can also be used to participate in limited-time events, such as holiday specials, social tournaments, etc. By participating, players can earn additional rewards. This design will strengthen players' sense of community involvement, allowing them to enjoy fun outside the game as well.
      </li>
      <li>
        <b>● Exchange and Trading:</b>
        Players can trade AVC tokens with other players or exchange them on exchanges. This feature allows players to freely manage and use their assets, increasing the liquidity and tradability of the token.
      </li>
      <p></p>

      <h3>Economic Model</h3>
      <h6>Risk Management:</h6>
      <li>
        <b>● Market Monitoring and Adjustments:</b>
        We will continuously monitor market trends and player behavior, adjusting token issuance and redemption strategies in a timely manner to address potential economic fluctuations and market changes. This will help build player trust in the token economy.
      </li>
      <li>
        <b>● Transparent Financial Reporting:</b>
        We will regularly publish financial reports and token circulation details to ensure transparency in the token economy, increasing player trust and participation.
      </li>
      <p>Through these enriched token economic designs, we aim to provide players with a fair, transparent, and incentivizing gaming environment, allowing them to enjoy the game while gaining real economic benefits.</p>
      
      <h3>Economic Model</h3>
      <h6>Supply and Demand:</h6>
      <li>
        <b>● Limited Supply:</b>
        The total supply of AVC tokens is set to be limited to ensure scarcity. The specific total supply will be based on market demand and the development stage of the game. For example, the initial supply might be set at 100 million tokens, and as the player base grows and the game expands, a small amount may be issued additionally. However, the total supply will be controlled within a reasonable range to maintain the token's value.
      </li>
      <li>
        <b>● Dynamic Adjustment Mechanism:</b>
        We will regularly review the circulation of tokens based on market conditions and player engagement. Through smart contracts, we can automate adjustments to token issuance and redemption to maintain economic balance. This mechanism ensures that when player activity drops, we can take swift actions to protect the token's value.
      </li>
      <h6>Token Buyback Mechanism:</h6>
      <li>
        <b>● Buyback Plan: </b>
        To further enhance the rarity of AVC tokens, we will periodically buy back a certain number of tokens from the market. This will be done through game revenues, betting fees, event profits, etc. Token buybacks will help reduce the circulating supply, thus increasing the value of the remaining tokens.
      </li>
      <li>
        <b>● Buyback Transparency:</b>
        Each buyback will be recorded on the blockchain to ensure transparency, allowing community members to check the buyback status at any time. This transparency will enhance players' confidence in the token's value and foster trust within the community.
      </li>
      <h6>Locking Mechanism:</h6>
      <li>
        <b>● Locking Period Design:</b>
        To ensure the long-term value and stability of the token, we will introduce a token locking mechanism. A portion of the tokens will be locked for a specific period, preventing trading or selling. This will prevent large-scale sell-offs and help maintain token scarcity and price stability.
      </li>
      <li>
        <b>● Rewards and Unlocking: </b>
        After the locking period ends, players will be able to receive unlocking rewards. These rewards may include additional AVC tokens or other in-game rewards to encourage players to hold tokens and invest in the long term. This design will help build player loyalty and community stability.
      </li>
      <h6>Incentive Mechanism:</h6>
      <li>
        <b>● Reward Structure:</b>
        We will establish a multi-level reward structure to encourage players to earn AVC tokens through various activities. In addition to completing daily tasks and events, players will receive token rewards for every interaction within the game, such as defeating enemies, successfully escaping, etc.
      </li> 
      <li>
        <b>● Holding Rewards: </b>
        To encourage players to hold AVC tokens for the long term, we will introduce a holding reward mechanism. Players who hold tokens for a certain period will earn additional token rewards. This mechanism aims to increase the circulating time of tokens, thus raising their value.
      </li>
      <li>
        <b>● Community Governance:</b>
        AVC token holders will have the right to participate in the governance of the game community, such as voting on new features, proposing events, and other decisions. This will increase players’ sense of participation and encourage them to actively engage in the future development of the game.
      </li>
      <p></p>


      <h3>Vault (Treasury)</h3>
      <p></p>
      <p>The Vault is a core component of the game economy, serving as the centralized repository for all game revenues. After receiving these funds, the Vault will use them to purchase AVC tokens on the open market. The purchased AVC tokens will then be distributed to token holders, ensuring fair and transparent distribution of game profits. The Vault mechanism plays a crucial role in maintaining the stability and sustainability of the game ecosystem, while also incentivizing token holders to continuously invest and actively participate in the growth and development of the game.</p>
     
      <h3>Conclusion</h3>
      <h6>Reaffirming the Vision</h6>
      <p>Our goal is to create a fair, transparent, and fun gaming experience by combining blockchain technology with battle royale game mechanics, attracting more players to participate. We believe that through our efforts, players will not only enjoy the fun of the game but also gain real economic benefits.</p>
      <!-- <li>
        <b>●</b>
      </li> -->
    </div>
  </div>
</template>

<script>
export default {
  setup() {},
}
</script>

<style lang="less" scoped>
.PrivacyPolicy {
  padding-top: 60px;
  background: #070707;
  color: #fff;
  overflow: hidden;
  .center {
    width: 1300px;
    margin: 0 auto;
    // margin-top: 150px;
    color: #fff;
    padding-top: 100px;
    margin-bottom: 50px;
    h3 {
      font-size: 24px;
      text-align: left;
      // margin-bottom: 30px;
    }
    h4 {
      font-size: 18px;
      // text-decoration: underline;
      margin-top: 20px;
      margin-bottom: 5px;
    }
    p {
      line-height: 25px;
      font-size: 14px;
      margin-bottom: 30px;
      text-align: justify;
      word-wrap: break-word;
      font-weight: 400;
    }
    li {
      line-height: 25px;
      font-size: 14px;
      text-align: justify;
      word-wrap: break-word;
      font-weight: 400;
    }
    h5 {
      // text-decoration: underline;
      text-align: left;
      margin-bottom: 20px;
    }
    h6 {
      text-align: left;
      margin: 20px 0;
    }
  }
}
</style>
