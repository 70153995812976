const en = {
  menu: {
    header: 'zh'
  },
  header: {
    connect: 'Connect',
  },
  menu: {
    bottom: 'zh'
  },
  bottom: {
    btmFollow: 'Follow us',
    privacy: '隐私',
    sponsorList: [
      {
        name: "Introduction",
      },
      {
        name: "AVC",
      },
      {
        name: "Community",
      },
      {
        name: "WHITEPAPER",
      },
      // {
      //   name: "Trademark Policy",
      // },
      
      // {
      //   name: "iPolloverse",
      // },
      // {
      //   name: "Element NFT Marketplace",
      // },
    ]
  },
  menu: {
    home: 'home'
  },
  home: {
    // 首屏
    homeTitle: 'Lost Caverns',
    homeBanner1: 'Welcome to the Underground Adventure World!',
    homeBanner2: 'Start digging and uncover your treasures!',
    homeSubscribeBtn: 'Coming Soon',
    homeDocs: 'Start',
    homeTrusted: '',

    // 介绍 
    // 手风部分
    homeHand: 'Escape',
    homeHandContent: "Dodge the Bomber Split the Jackpot!",
    homeGuess: 'Guess NO',
    homeGuessContent: 'Guess the BTC Price, Earn Nova!',
    homeRiddle: 'Riddle',
    homeRiddleContent: "Guess Numbers A Battle of Luck",
    homeMulti: 'Multiplayer Battle!',
    homeMultiContent: 'Fierce Showdown! Become the Last Winner!',
    
    // 动画部分
    homeSupporting: 'Escape: Your Choice, Life or Death in an Instant!',
    homeSupportingContent: "Choose your room and face the volatile BTC price movements—dodge the deadly attacks of the Bomb Man with your precise guesses! In this high-speed battle of wits and reflexes, can you outsmart everyone and become the last survivor?",
    homeInteroper: 'Guess NO: Master the Market, Predict the Future!',
    homeInteroperContent: 'With BTC price fluctuations every 5 seconds, challenge your quick judgment! Guess whether the price will rise or fall, earn hefty rewards with smart predictions, and become the true king of the Price Wave!',
    homeCoMpos: 'Riddle: The Ultimate Test of Wit and Luck!',
    homeCoMposContent: "Pick your lucky number from 49 options, with the draw determined by the transfer hash value. A true test of both luck and intelligence—win treasures fast! Are you ready to face the challenge?",
    homeTranspraent: 'Multiplayer Guess NO.: 5 Players, Who Will Stand Tall in the End?',
    homeTranspraentContent: "Compete with four others in real-time—guess correctly to stay in the game, guess wrong and you're out! Three intense rounds of head-to-head competition—who will rise as the ultimate winner and walk away with the grand prize? Are you up for the challenge?",

    // avc
    homeCenoTit: 'AVC',
    homeGame: 'Limited Supply, Rare Value',
    homeGameContent1: '', // 暂时没有用到
    homeGameContent2: "The total supply of AVC tokens is limited, ensuring their scarcity and value. Each AVC token represents unique economic potential within the game, and its value will continue to rise as the player base grows.",
    homeGovernance: 'Upcoming Exchange Listing',
    homeGovernanceContent: "AVC tokens will soon be listed on major exchanges, offering players and investors increased liquidity and trading opportunities. As the market matures, the value of AVC tokens will be further recognized and appreciated.",
    homeVeZOIC: 'Strong Future Potential',
    homeVeZOICContent1: "As one of the first to acquire AVC tokens, you’ll have a unique opportunity to participate in the future development of the ecosystem. With the continued expansion of the token economy, you will not only enjoy the game but also gain substantial economic rewards.",
    homeVeZOICContent2: "",
    homeVeZOICBtn: 'Coming Soon',

    // 加入我们
    homeHowTit: 'Stay with us',
    homeDepositsTit: 'Join Us and Become Part of the Game Community!',
    homeDepositsContent: 'Here, players are not just participants in the game, but also the builders of the community. Join our big family and create, share, and challenge alongside like-minded players. With a variety of events and unique rewards waiting for you to discover, come join us and start your adventure journey!',
    homeEarnTit: 'Be Active and Enjoy Endless Fun!',
    homeEarnContent: "We encourage every player to actively participate in community interactions and activities, share experiences, and improve their skills. Whether it's discussing strategies, taking on challenges, or earning community rewards, every bit of activity brings you closer to the fun of the game. Every participation here brings surprises, allowing you to always feel the thrill and sense of achievement!",
    // homeReceiveTit: '',
    // homeReceiveContent: 'Join us and stay updated with the latest news!',


    // 社区
    homeCommunity: 'Community',
    homeCommunity1: 'Limited Tokens, Don’t Miss Out！',
    homeConent: 'The supply of AVC tokens is limited, and every distribution could be your only chance to secure this valuable asset! Stay active in the game and complete tasks to earn token rewards and maximize their value in future markets.',
    homeCommunity2: ' Get NOVA＆AVC',
    homeCommunity3: 'Join The Community',
    // homeAdvantageLift1: 'Reduce financial risk with stablecoin game currency and redeemable NFTs.',
    // homeAdvantageLift2: 'Increase earnings with CENO gameplay and ZOIC rewards, plus governance voting rights.',
    // homeAdvantageLift3: 'For Players',
    // homeAdvantageRight1: 'Developers can guide ZOIC reward distribution by acquiring veZOIC voting rights, attracting more players to their games.',
    // homeAdvantageRight2: 'For Game Developers',
  },
  menu: {
    homeDialog: 'en'
  },
  homeDialog: {
    dialogTitlte: 'Get our newsletter',
    dialogInput: 'Enter your email',
    dialogRules1: 'Enter your email',
    dialogRules2: 'Please fill out this field.',
    dialogConfirm: 'Confirm',
    dialogCancel: 'Cancel',
  },
}


export default en